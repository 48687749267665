@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  font-family: "Roboto", sans-serif;
}

a {
  -webkit-tap-highlight-color: transparent;
}

.border_red {
  border: 2px solid red;
}
.border_black {
  border: 2px solid black;
}
.border_green {
  border: 2px solid green;
}

.swipeable-list-item__content {
  background-color: transparent !important;
  padding: 0 17px !important;
}

.swipeable-list {
  height: 26% !important;
}
@media screen and (max-width: 1351px) {
  .swipeable-list {
    height: 30% !important;
  }
}
@media screen and (max-width: 1084px) {
  .swipeable-list {
    height: 35% !important;
  }
}
@media screen and (max-width: 845px) {
  .swipeable-list {
    height: 43% !important;
  }
}
@media screen and (max-width: 721px) {
  .swipeable-list {
    height: 43% !important;
  }
}
@media screen and (max-width: 678px) {
  .swipeable-list {
    height: 48% !important;
  }
}
@media screen and (max-width: 632px) {
  .swipeable-list {
    height: 52% !important;
  }
}
@media screen and (max-width: 602px) {
  .swipeable-list {
    height: 57% !important;
  }
}
@media screen and (max-width: 542px) {
  .swipeable-list {
    height: 57% !important;
  }
}
@media screen and (max-width: 528px) {
  .swipeable-list {
    height: 62% !important;
  }
}
@media screen and (max-width: 488px) {
  .swipeable-list {
    height: 70% !important;
  }
}
@media screen and (max-width: 411px) {
  .swipeable-list {
    height: 60% !important;
  }
}
@media screen and (max-width: 393px) {
  .swipeable-list {
    height: 84% !important;
  }
}
@media screen and (max-width: 375px) {
  .swipeable-list {
    height: 68% !important;
  }
}
@media screen and (max-width: 360px) {
  .swipeable-list {
    height: 92% !important;
  }
}
@media screen and (max-width: 320px) {
  .swipeable-list {
    height: 121% !important;
  }
}

